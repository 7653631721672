<script setup lang="ts">
import type { DocumentType } from '@register'
import DropTarget from '@uppy/drop-target'
import FileInput from '@uppy/file-input'
import type { Meta, UppyEventMap } from '@uppy/core'
import Uppy from '@uppy/core'
import XHR from '@uppy/xhr-upload'
import { FILE_SIZE_UPLOAD_LIMIT } from '~/stores/upload'
import { toast } from 'vue3-toastify'
import { useQueryClient } from '@tanstack/vue-query'

// PROPS
const props = defineProps<{ documentType: DocumentType }>()

// EMITS
const emit = defineEmits<{
  upload: [
    ...args: Parameters<UppyEventMap<Meta, Record<string, unknown>>['upload']>,
  ]
  uploadSuccess: [
    ...args: Parameters<
      UppyEventMap<Meta, Record<string, unknown>>['uploadSuccess']
    >,
  ]
  uploadError: [
    ...args: Parameters<
      UppyEventMap<Meta, Record<string, unknown>>['uploadError']
    >,
  ]
}>()

// CURRENT COMPANY
const companyId = getCurrentDivisionId()
const { currentDivision } = useDivisions()

// STATE
const disabled = ref(false)

// COUNTERS
const uploading = ref<number>(0)
const finished = ref<number>(0)

// COMPUTED
const isUploading = computed(() => uploading.value > 0)
const hasUploaded = computed(() => finished.value > 0)
const hasFiles = computed(() => hasUploaded.value || isUploading.value)

// UPLOADER
const apiURL = useRuntimeConfig().public.apiURL
const leaseId = getCurrentLeaseId()
const mixpanel = useMixpanel()
const queryClient = useQueryClient()
const uppy = new Uppy({
  autoProceed: true,
  restrictions: {
    maxFileSize: FILE_SIZE_UPLOAD_LIMIT,
    maxNumberOfFiles: 2000,
    minNumberOfFiles: 1,
    allowedFileTypes: ['application/pdf'],
  },
})
  .use(XHR, {
    endpoint: `${apiURL}/companies/${companyId}/leases/${leaseId}/documents/upload`,
    formData: true,
    bundle: false,
    fieldName: 'file',
    withCredentials: true,
  })
  .on('file-added', (file) => {
    disabled.value = true

    uppy.setFileMeta(file.id, {
      name: file.name,
      type: props.documentType,
    })

    useAnalytics({
      name: 'Upload: File added',
      showDivision: true,
      data: {
        source: file.source,
        name: file.name,
        size: file.size,
        type: file.type,
      },
    })
  })
  .on('upload', (uploadID, files) => {
    uploading.value++
    emit('upload', uploadID, files)
  })
  .on('upload-success', async (file, response) => {
    uploading.value--
    finished.value++
    mixpanel.people?.increment('Total Files Uploaded')

    await queryClient.invalidateQueries({
      queryKey: ['companies', companyId, 'leases', leaseId, 'documents'],
    })

    await queryClient.invalidateQueries({
      queryKey: ['companies', companyId, 'leases', leaseId],
      exact: true,
    })

    queryClient.invalidateQueries({
      queryKey: ['companies', companyId, 'leases', leaseId, 'fields'],
    })

    queryClient.invalidateQueries({
      queryKey: ['getLeaseDocumentId', companyId, leaseId],
      exact: true,
    })

    useAnalytics({
      name: 'Upload: Completed',
      showDivision: true,
      data: {
        source: file?.source,
        name: file?.name,
        size: file?.size,
        type: file?.type,
      },
    })
    emit('uploadSuccess', file, response)
  })
  .on('error', (error) => {
    console.error(error)
  })
  .on('upload-error', (file, error, response) => {
    if (!file) return
    uploading.value--
    let message = `There was an error to upload ${file.name}`
    if (error.message) {
      message = `<strong class="font-semibold">${file!.name}</strong> \n${
        error.message
      }`
    }
    toast.error(message)
    useAnalytics({
      name: 'Upload: Error',
      showDivision: true,
      data: {
        source: file.source,
        name: file.name,
        size: file.size,
        type: file.type,
        message: error.message,
      },
    })
    emit('uploadError', file, error, response)
  })
  .on('upload-retry', (fileID) => {
    useAnalytics({
      name: 'Upload: Retry',
      showDivision: true,
      data: { fileID: fileID },
    })
  })
  .on('restriction-failed', (file, error) => {
    if (!file) return

    const oldFileWithError = uppy
      .getFiles()
      .filter((f) => file.name === f.name && f.error)

    const isCurrentDuplicated = error.message.includes(
      'Cannot add the duplicate file',
    )

    if (oldFileWithError.length > 0 && isCurrentDuplicated) {
      uppy.removeFile(oldFileWithError[0].id)
      setTimeout(() => {
        // @ts-expect-error - The UppyFile satisfies the MinimalRequiredUppyFile
        uppy.addFile(file)
      }, 200)
    } else {
      toast.error(
        `<strong class="font-semibold">${file!.name}</strong> \n${
          error.message || error.name
        }`,
      )
      useAnalytics({
        name: 'Upload: Restriction Failed',
        showDivision: true,
        data: {
          source: file?.source,
          name: file?.name,
          size: file?.size,
          type: file?.type,
          message: error.message || error.name,
        },
      })
    }
  })

// INITIALIZE
const dropTarget = ref<HTMLElement>()
const fileSelector = ref<HTMLElement>()
onMounted(() => {
  uppy.use(DropTarget, {
    target: dropTarget.value!,
  })
  uppy.use(FileInput, {
    target: fileSelector.value!,
    locale: {
      strings: {
        chooseFiles: 'Browse',
      },
    },
  })
})

// EXPOSE
defineExpose({ uppy })
</script>

<template>
  <div
    ref="dropTarget"
    :data-company-name="currentDivision?.name"
    class="relative mt-4 hidden w-full flex-col justify-between md:flex md:flex-row"
    :class="disabled && 'pointer-events-none opacity-50'"
  >
    <div
      class="flex w-full flex-col transition-all md:pb-0"
      :class="[hasFiles ? 'pb-4' : 'pb-0']"
    >
      <section
        class="relative my-4 flex w-full flex-col rounded-lg border-2 border-dashed border-gray-300 bg-gray-200 py-10 transition-all dark:border-gray-700 dark:bg-gray-800"
        :class="[hasFiles ? 'py-4' : 'py-10']"
      >
        <span
          class="bg-primary/5 after:border-primary relative flex self-center rounded-full transition-all after:absolute after:inset-0 after:rounded-full after:border after:border-dashed after:content-['']"
          :class="[
            isUploading
              ? 'after:animate-spin-10-sec mb-2 p-2'
              : 'after:animate-spin-1-min mb-4 p-4',
          ]"
        >
          <Icon
            name="icon-upload-cloud"
            class="text-primary flex items-center justify-center"
            :class="{
              'h-7 w-7 text-2xl': !hasFiles,
              'h-5 w-5': hasFiles,
            }"
          />
        </span>

        <span
          class="flex items-center justify-center gap-2 font-bold text-white"
          :class="{
            'text-lg': hasFiles,
            'text-xl': !hasFiles,
          }"
        >
          Drag & Drop
          <span v-if="isUploading">more</span>
          Files, or
          <span ref="fileSelector" class="uppy-file-selector" />
        </span>
        <div
          v-if="!hasFiles"
          v-auto-animate
          class="mt-4 px-4 text-center text-sm text-gray-400"
        >
          <p>
            Please ensure that the PDF document is legible and less than 20mb
          </p>
          <p>
            Upload speed is determined by the internet connection being used
          </p>
        </div>
        <!-- Progress bar -->
        <div
          v-if="isUploading"
          class="absolute bottom-px left-1 right-1 h-0.5 rounded-full"
        >
          <div
            class="animate-start-material-progress bg-primary relative m-0 h-full overflow-hidden"
          >
            <div class="bar animate-bar1"></div>
            <div class="bar animate-bar2"></div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
:deep(.uppy-Dashboard-inner) {
  @apply !h-[200px] !w-full bg-neutral-200 md:!h-[500px] dark:bg-gray-700;
}

:deep(.uppy-Dashboard-AddFiles-title) {
  @apply text-black dark:text-white;
  button {
    @apply text-primary;
  }
}

:deep(.uppy-Dashboard-poweredBy) {
  @apply hidden;
}

.uppy-is-drag-over {
  &::before {
    @apply top-navigation bg-primary-50/80 text-primary fixed bottom-0 left-0 right-0 z-10 flex items-center justify-center rounded-lg border-4 border-dashed pb-10 text-center text-4xl font-bold backdrop-blur-3xl backdrop-saturate-[180%];
    content: attr(data-company-name);
  }

  &::after {
    @apply border-primary fixed bottom-1 left-1 right-1 top-[64px] z-10 flex items-center justify-center rounded-lg pt-10 text-center text-2xl font-bold text-black;
    content: 'Drag and drop PDF files here';
  }
}

:deep(.uppy-FileInput-btn) {
  @apply text-primary focus-within:bg-primary focus-within:ring-primary/20 rounded-lg bg-transparent focus-within:outline-none focus-within:ring hover:underline;
}
</style>
